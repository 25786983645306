import { WatchQueryFetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const useCurrentUser = (fetchPolicy?: WatchQueryFetchPolicy) => {

    const { data, error, loading} = useQuery<CurrentUserData>(CURRENT_USER_QUERY, {
        fetchPolicy: fetchPolicy ?? 'cache-first'
    });

    return {
        data,
        error,
        loading
    };

};

export interface CurrentUserData {
    appUser: AppUser;
}

export interface AppUser {
    userId:          string;
    staffGuid:       string;
    name:            string;
    userName:        string;
    isAdministrator: boolean;
    role:            string;
    isInternal:      boolean;
}

const CURRENT_USER_QUERY = gql`
    query CurrentUser {
        appUser {
            userId
            staffGuid
            name
            userName
            isAdministrator
            role
            isInternal
        }
    }
`;

import { WatchQueryFetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const useCrmDuplicateCheck = (params: DuplicateCheckParams, fetchPolicy?: WatchQueryFetchPolicy) => {
  const { data, error, loading } = useQuery<DuplicateCheckData, DuplicateCheckParams>(CrmDuplicateCheckQuery, {
      fetchPolicy,
      variables: {
        email: params.email,
        isIncludeParty: params.isIncludeParty,
        isIncludeEnquiry: params.isIncludeEnquiry,
      }
  });

  return {
      data,
      error,
      loading,
  };
};

export type DuplicateCheckParams = {
   email: string | null;
   isIncludeParty?: boolean | null
   isIncludeEnquiry: boolean | null
};

export type DuplicateCheckData = {
    crm: CRM;
};

export type CRM = {
    duplicateCheck: DuplicateCheck;
};

export type DuplicateCheck = {
    contactName:                  string;
    contactEmailAddress:          string;
    enquiryName:                  string;
    enquiryEmailAddress:          string;
    otherSideName:                string;
    otherSideEmailAddress:        string;
    partyDuplicateCount:          number;
    enquiryContactDuplicateCount: number;
    mobile:                      string;
    phone:                      string;
};

export const CrmDuplicateCheckQuery = gql`
    query CrmDuplicateCheck(
        $email: String
        $isIncludeParty: Boolean
        $isIncludeEnquiry: Boolean
    ) {
        crm {
            duplicateCheck(
                email: $email
                isIncludeParty: $isIncludeParty
                isIncludeEnquiry: $isIncludeEnquiry
            ) {
                contactName
                contactEmailAddress
                enquiryName
                enquiryEmailAddress
                otherSideName
                otherSideEmailAddress
                partyDuplicateCount
                enquiryContactDuplicateCount
                mobile
                phone
            }
        }
    }
`;
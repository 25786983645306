import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const useCurrencySymbol = () => {
    const { data, error, loading } = useQuery<CurrencySymbolData>(CURRENCY_SYMBOL_QUERY, {
        fetchPolicy: 'cache-first'
    });
    return {
        data,
        error,
        loading,
    };
};

export interface CurrencySymbolData {
    options: Options;
}

export interface Options {
    currencySymbol: CurrencySymbol;
    localCurrency:  LocalCurrency;
}

export interface CurrencySymbol {
    symbol: string;
}

export interface LocalCurrency {
    currency: string;
}

const CURRENCY_SYMBOL_QUERY = gql`
    query CurrencySymbol {
        options {
            currencySymbol {
                symbol
            }
            localCurrency {
              currency
            }
        }
    }
`;

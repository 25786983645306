/* eslint-disable no-useless-concat */

import React, { useState, useEffect } from 'react';
import {
    makeStyles,
    Theme,
    createStyles,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { mainTheme } from '../../Theme';
import { showNotification } from '../../App';
import { client } from '../..';
import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { fetchTypedUserSettings } from '../../userSettings/UserSettingsRepository';
import { MatterTerminology } from '../../MatterTerminology';
import { DocumentListFile } from '../../common/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        textField: {
            width: '100%',
        },
        commendField: {
            width: '100%',
        },
        dialogTitle: {
            color: mainTheme.TemplateColor.Primary,
            '& .MuiTypography-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }
        },
        dropzone: {
            paddingTop: 10,
            paddingBottom: 10,
        },
        matterLabel: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
        },
        textArea: {
            paddingBottom: '10px',
            width: '100%',
            '& textarea': {
                fontSize: '16px',
            },
            '& > div::before': {
                // content: 'none',
            }
        },
        camelCaseButton: {
            textTransform: 'none'
        }
    })
);

interface SupportEmailState {
 
    note: string;
    userName: string;
    tenant: string;
    supportEmail: string;
}

export interface SupportEmailProps {
    onClose: () => void;
}

// tslint:disable-next-line: no-anyW
export const SupportEmailDialog: React.FC<SupportEmailProps> = ( props ) => {
    const classes = useStyles();

    const [state, setState] = useState<SupportEmailState>({
        note: '',
        userName: '',
        tenant: '',
        supportEmail: ''
    });

    useEffect(() => {
        fetchData();
    // tslint:disable-next-line:align
    }, []);

    // tslint:disable-next-line:no-any
    const handleTextChange = (event: any) => {
        const note = event.target.value;
        setState((prevState) => {
            return {
                ...prevState,
                note: note 
            };
        });
    };
    const handleSend = () =>  {
        if (state.note !== '') {
            var body = GenerateEmailBody();
            var subject = 'User Web Issue';
            var recipient = 'support@redraincorp.com';
            var cc = '';
            if (state.supportEmail !== recipient && state.supportEmail) {
                cc = '; ' + state.supportEmail;

            }

            var mailToString = 'mailTo:' + recipient + cc + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body); 
            window.open(mailToString);
        }
        if (props.onClose) {
          return props.onClose();
        }
    };

    const GenerateEmailBody = () => {
        var body = state.note 
        + '\n\n User: ' + getUserName()
        + '\n Tenant: ' + state.tenant
        + '\n App: ' + 'Connect Web'
        + '\n Browser: ' + detectBrowser()
        // tslint:disable-next-line:no-string-literal
        // + '\n NetworkType: ' + navigator['connection'].effectiveType
        + '\n TimeZoneOffset: ' + new Date().getTimezoneOffset() / 60.0;
        return body;
        
    };

    const getUserName = () =>  {
       return state.userName ? state.userName : 'Not logged in';
    };

    const detectBrowser = () =>  { 
        if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1 ) {
            return 'Opera';
        } else if (navigator.userAgent.indexOf('Chrome') !== -1 ) {
            return 'Chrome';
        } else if (navigator.userAgent.indexOf('Safari') !== -1) {
            return 'Safari';
        } else if (navigator.userAgent.indexOf('Firefox') !== -1 ) {
            return 'Firefox';
        } else if (navigator.userAgent.indexOf('MSIE') !== -1 ) {
            return 'IE';
        } else {
            return 'Unknown';
        }
    };

    const fetchData = () =>  {
        fetchTypedUserSettings(
            true,
            // tslint:disable-next-line:no-any
            (data: any) => { setState((prevState: SupportEmailState) => {
                return {
                    ...prevState,
                    supportEmail: data.timeslipContactInfo,
                };
            });
            }, 
            reason => {
              showNotification(
                'Failed to Fetch Layout Configuration',
                reason,
                'error'
              );
            }
          );
        fetchUserData(false, 
                      // tslint:disable-next-line:no-any
                      (data: any) => {  setState((prevState: SupportEmailState) => {
                            return {
                                ...prevState,
                                userName: data.appUser ? data.appUser.userName : '',
                                tenant:  data.tenant.tenantId
                            };
                        });
                      }, 
                      (reason) => {   
                          showNotification('Failed to Matter Summary New Enabled Lookup value', reason, 'error'); 
                      });
    };

    const onServiceLevel = () => {
        window.open(DocumentListFile.ServiceLevel, '_blank');
    };

    return (
        <React.Fragment>
            <Dialog
                open={true}
                onClose={() => props.onClose()}
                className={classes.root}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.dialogTitle}
                >
                    <div>Request Support</div>
                    <Button 
                        color="primary"
                        onClick={onServiceLevel}
                        className={classes.camelCaseButton}
                    >
                        {MatterTerminology.ServiceLevels}
                    </Button>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid item={true} xs={12}>
                        <Grid container={true} spacing={1}>    
                            <TextField
                                className={classes.textArea}
                                id="outlined-multiline-static"
                                // label="Multiline"
                                multiline={true}
                                rows="10"
                                value={state.note}
                                onChange={handleTextChange}
                            />
                        </Grid>
                    </Grid>                      
                    
                    {/* {printJson(values)} */}
                </DialogContent>
                <DialogActions>
                    <Typography>If you are reporting on an issue or an error please provide as much specific detail as possible to the nature of the problem.
                        Screen shots are often very useful. When you click SEND, an email will be created in your default email application.
                        Any attachments or screenshots can be added on that screen.</Typography>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    <>
                        <Button 
                            color="primary"
                            type="submit"
                            onClick={handleSend}
                        >
                            Send
                        </Button>
                        {/* <Button 
                            onClick={() => {
                                setKey(Math.random().toString());
                                form.reset();
                            }} 
                            disabled={submitting || pristine} 
                            color="primary"
                        >
                            Reset
                        </Button> */}
                        <Button 
                            onClick={() => props.onClose()} 
                            color="primary"
                        >
                            Close
                        </Button>
                    </>
                    
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export function fetchUserData(refreshData: boolean,
                                // tslint:disable-next-line:no-any
                              onSuccess: (data?: any) => void,
                                // tslint:disable-next-line:no-any
                              onError: (reason: any) => void)
    : void {
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

        client.query({
            query: userInfoQuery,
            variables: {},
            fetchPolicy: fetchPolicy
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
            .catch((reason: any ) => { onError(reason); }
        );
}

const userInfoQuery = gql`query user {
    appUser {
      userName
    }
    tenant {
      tenantId
    }
  }
  `;

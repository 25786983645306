import { UpIcon, DownIcon } from '../home/Common';
import React from 'react';
import moment from 'moment';
import { LocalStorageEnum } from '../enums/LocalStorageEnum';

export function GetGroupByPlurals(groupBy: string) {
    switch (groupBy) {
        case 'MatterType':
            return 'matter types';
        case 'MatterCategory':
            return 'matter categories';
        case 'MatterCategoryAndType':
            return 'matter categories/types';
        case 'PersonActing':
            return localStorage.getItem(LocalStorageEnum.PersonActing)?.toLocaleLowerCase() || 'person acting';
        case 'PersonResponsible':
            return localStorage.getItem(LocalStorageEnum.PersonResponsible)?.toLocaleLowerCase() || 'person responsible';
        case 'Property':
            return 'properties';
        case 'PropertyType':
            return 'property types';
        case 'ReportingGroup':
            return 'reporting groups';
        case 'AreaOfLaw':
            return 'areas of law';
        case 'Source':
            return 'sources';
        default:
            return '';
    }
}

export function GetGroupBy(groupBy: string) {
    switch (groupBy) {
        case 'MatterType':
            return 'Type';
        case 'MatterCategory':
            return 'Category';
        case 'MatterCategoryAndType':
            return 'Category/Type';
        case 'PersonActing':
            return localStorage.getItem(LocalStorageEnum.PersonActing) || 'Person Acting';
        case 'PersonResponsible':
            return localStorage.getItem(LocalStorageEnum.PersonResponsible) || 'Person Responsible';
        case 'Property':
            return 'Property';
        case 'PropertyType':
            return 'Property Type';
        case 'ReportingGroup':
            return 'Reporting Group';
        case 'Client':
            return 'Client';
        default:
            return '';
    }
}

export function GetLeadsGroupBy(groupBy: string) {
    switch (groupBy) {
        case 'AreaOfLaw':
            return 'Area of Law';
        case 'Source':
            return 'Lead Source';
        case 'Assignee':
            return 'Assignee';
        case 'Location':
            return 'Location';
        case 'Quality':
            return 'Quality';
        case 'LikelihoodToProceed':
            return 'Likelihood to Proceed';
        default:
            return '';
    }
}

export function formatNumber(value: number, decimal?: number) {
    return value
        .toFixed(decimal ? decimal : 0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// returns date format of yyyy-MM-dd from a
// a valid date string
export function formatToDbDate(date: string) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return [year, month, day].join('-');
}

export function convertStringNumberToNumber(value: string) {
    return parseFloat(value.replace(/,/g, ''));
}

export function formatPeriod(period: string) {
    switch (period) {
        case 'TwelveMonths':
            return '12 Months';
        case 'ThirtyDays':
            return '30 Days';
        case 'NinetyDays':
            return '90 Days';
        case 'SixtyDays':
            return '60 Days';
        default:
            return '';
    }
}

export function formatPeriodNoSpace(period: string) {
    switch (period) {
        case 'TwelveMonths':
            return '12Months';
        case 'ThirtyDays':
            return '30Days';
        case 'NinetyDays':
            return '90Days';
        case 'SixtyDays':
            return '60Days';
        default:
            return '';
    }
}

export function formatToDatePeriod(
    period: 'monthToDate' | 'previousMonth' | 'yearToDate' | 'previousYearToDate' | 'previousMonthYearToDate' | 'yearToDateFees' | 'previousMonthYearToDateFees',
) {
    switch (period) {
        case 'monthToDate':
            return 'Month to Date';
        case 'previousMonth':
            return 'Previous Month';
        case 'yearToDate':
            return 'Year to Date';
        case 'previousYearToDate':
            return 'Previous Financial Year';
        case 'previousMonthYearToDate':
            return 'Year to Date (Previous Month)';
        case 'yearToDateFees':
            return 'Year to Date Fees';
        case 'previousMonthYearToDateFees':
            return 'Year to Date Fees (Previous Month)';
        default:
            return 'Month to Date';
    }
}

export function formatDate(date: Date, format: string) {
    return moment(date).format(format);
}

export function FormatHours(value: number) {
    return value > 2 ? 'hours' : 'hour';
}

// tslint:disable-next-line:no-any
export function GetArrow(value1: any, value2: any) {
    if (value1 > value2) {
        return <UpIcon className="increase" />;
    } else if (value1 < value2) {
        return <DownIcon className="decrease" />;
    }
    return '';
}

export function GetSuggestedMax(value: number) {
    if (value <= 10) {
        return value + 1;
    }

    return value + value * 0.1;
}

export function FormatDate(value: string) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var date = new Date(value);
    return months[date.getMonth()] + ' ' + date.getDate();
}

export function checkArrayIfNotAllZero(array: number[], length: number) {
    return array.filter((x) => x > 0).length >= length;
}

export function getHighestNumberInArray(array: number[]) {
    return Math.max(...array);
}

/*
* getMaxSuggestedTick is Deprecated please use getSuggestedMaxTick
*/
export function getMaxSuggestedTick(value: number, suggested: number) {
    if (value <= suggested) {
        const generatedValue = value + Math.round(value * 0.1);

        return generatedValue < 5 ? generatedValue + 1 : generatedValue;
    }
    return value + value * 0.1;
}

/*
* get the suggested max tick limit
*/
export function getMaxTickLimit(array: number[]) {
    // 11 is the default max tick limit in chartjs
    let maxTicksLimit = 11;
    if (array.length > 0) {
        const maxNumber = getHighestNumberInArray(array);

        if (maxNumber >= 11) {
            maxTicksLimit = maxNumber;
        }
    }

    return maxTicksLimit;
}

/*
* get the suggested max tick
*/
export function getSuggestedMaxTick(array: number[]) {
    // 6 is the default max tick in chartjs
    let suggestedMax = 6;
    if (array.length > 0) {
        
        const maxNumber = getHighestNumberInArray(array);

        if (maxNumber >= 6) {
            suggestedMax = maxNumber + + Math.round(maxNumber * 0.1);
        }
    }

    return suggestedMax;
}

/*
* get labels and if lables array are empty automatically return array with empty string
*/
export  function getLabels(labels: string[]) {
    if (labels.length > 0) {
        return labels;
    }
    // this use to prevent returning 0 in the chart when has no data
    return [''];
}

export function convertFirstLetterToUppercase(input: string): string {
    return input.charAt(0).toUpperCase() + input.slice(1);
}

export enum ETimePeriod {
    THIS_MONTH = 'This Month',
    PREVIOUS_MONTH = 'Previous Month',
    FINANCIAL_YEAR = 'Financial Year',
    PREVIOUS_FINANCIAL_YEAR = 'Previous Financial Year',
    THIS_WEEK = 'This Week',
    LAST_14_DAYS = 'Last 14 Days',
    LAST_30_DAYS = 'Last 30 Days',
    LAST_12_MONTHS = 'Last 12 Months',
}
import { Theme, Tooltip, withStyles } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import { client } from '..';
import { FetchPolicy } from 'apollo-client';
import { DocumentNode } from 'graphql';

export function arrayToString(arr: (string | number | null)[]) {
    return arr.filter(Boolean).join(', ');
}

export function arrayToStringWithSeparator(arr: (string | number | null)[], separtor: string) {
    return arr.filter(Boolean).join(separtor);
}

export function addEllipsis(text: string, maxLength: number): string {
    return text.length > maxLength ? text.slice(0, maxLength - 3) + '...' : text;
}

export function removeTagsAndStyles(htmlString: string): string {
    // Remove HTML tags and &nbsp; using regex
    const withoutTagsAndNbsp = htmlString.replace(/(<([^>]+)>|&nbsp;)/gi, '');
  
    // Remove inline styles using regex
    const withoutStyles = withoutTagsAndNbsp.replace(/style\s*=\s*".*?"/gi, '');
  
    // Remove remaining whitespace and line breaks
    const withoutWhitespace = withoutStyles.replace(/\s+/g, ' ').trim();
  
    return withoutWhitespace;
  }

export const LightTooltip = withStyles((theme: Theme) => ({
    arrow: {
        color: theme.palette.common.white,
        '&::before': {
            // backgroundColor: 'blue',
            boxShadow: theme.shadows[1],
        }
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: '14px',
      maxWidth: '800px',
      fontFamily: 'Nunito, Arial, Helvetica, sans-serif',
      padding: '10px',
    },
}))(Tooltip);

export const finalFormValidateEmail = (value?: string) => {
        
    const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let error: string = '';

    if (!value) {
        return error;
    }
    
    if (!emailRegex.test(value)) {
        error = 'Email is not valid';
    }
    return error;
};

export function separateName(name: string): { firstName: string, lastName: string } {
    const words = name.split(' ');
    const firstName = words.shift(); // Removes and returns the first element of the array
    const lastName = words.join(' ');
  
    return {
      firstName: firstName || '', // If firstName is undefined, set it to an empty string
      lastName: lastName || '', // If lastName is undefined, set it to an empty string
    };
  }
  
export const addBusinessDays = (originalDate: Date, numDaysToAdd: number) => {
    const Sunday = 0;
    const Saturday = 6;
    let daysRemaining = numDaysToAdd;

    const newDate = moment(new Date(originalDate.valueOf()));

    while (daysRemaining > 0) {
        newDate.add(1, 'days');
        if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
            daysRemaining--;
        }
    }

    return newDate;
};

// convert muitext editor content that compose using :blocks then convert to react-wysyg
// tslint:disable-next-line: no-any
export function convertToDraftWysiwygContent(muiContent: any) {
    // tslint:disable-next-line: no-any
    const contentBlocks = muiContent.blocks.map((muiBlock: any) => ({
      text: muiBlock.text,
      type: muiBlock.type || 'unstyled', // Use 'unstyled' if type is missing
      depth: muiBlock.depth || 0,
      inlineStyleRanges: muiBlock.inlineStyleRanges || [],
      entityRanges: muiBlock.entityRanges || [],
      data: muiBlock.data || {},
    }));
  
    return {
      entityMap: muiContent.entityMap || {},
      blocks: contentBlocks,
    };
}

export function checkIfIntialValuesIsEqualToValues<T>(initialValues: T, values: T) {
    const keys = _.union(_.keys(initialValues), _.keys(values));
  
    return _.every(keys, (key) => {
      const a = initialValues[key];
      const b = values[key];
  
      if (_.isUndefined(a) || _.isNull(a)) {
        return _.isUndefined(b) || _.isNull(b);
      }
  
      return _.isEqual(a, b);
    });
}

// tslint:disable-next-line: no-any
export function retrieveQueryData<queryParams extends object, queryResult extends object, queryError = any>(
    query: DocumentNode,
    queryParams: queryParams,
    refreshData: boolean,
    onSuccess: (data: queryResult) => void,
    onError: (reason: queryError) => void,
) {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query<queryResult, queryParams>({
            query: query,
            variables: { ...queryParams },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason) => {
            onError(reason);
        });
}

export function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function hashString(input: string): number {
    var hash = 0, i, chr;
    if (input.length === 0) {
        return hash;
    }
    for (i = 0; i < input.length; i++) {
        chr = input.charCodeAt(i);
        // tslint:disable-next-line:no-bitwise
        hash = ((hash << 5) - hash) + chr - 1;
        // tslint:disable-next-line:no-bitwise
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

export function isDateDue(value: Date) {
    const currentDate = moment();
    const dateDiff = moment(value).diff(currentDate, 'days');

    return dateDiff < 0;
}
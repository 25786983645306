import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Button, DialogActions, Typography, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { showNotification } from '../../App';
import gql from 'graphql-tag';
import { client } from '../..';
import { RvLoader } from '../../components/Loader';
import { mainTheme } from '../../Theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        heading: {
            color: mainTheme.BrandColors.TemplatePrimary
        },
        loadingWrapper: {

        }
    })
);

interface DialogProps {
    open: boolean;
    onClose?: () => void;
}

interface DialogState {
    open: boolean;
    template: string | null;
    isLoading: boolean;
}

export const UserDefinedDialog: React.FC<DialogProps> = props => {
    
    const classes = useStyles();

    const [state, setState] = useState<DialogState>({
        open: false,
        template: null,
        isLoading: false,
    });

    useEffect(() => {
        setState((prevState) => {
            return {
                ...prevState,
                open: props.open
            };
        });

        fetchAppUser();
    // tslint:disable-next-line: align
    }, [props.open]);

    const fetchAppUser = () => {
        
        setState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            };
        });

        client.query({
            query: AppUserDefinedHelp,
            fetchPolicy: 'network-only'
            // tslint:disable-next-line:no-any
        }).then((results: {
            // tslint:disable-next-line:no-any
            data: any;
            }) => {

                let tempTemplate: string | null = null;

                if (results.data && results.data.appUser && 
                    results.data.appUser.userDefinedHelp &&
                    results.data.appUser.userDefinedHelp.template !== null ) {
                        
                    tempTemplate = results.data.appUser.userDefinedHelp.template;

                } else {
                    
                    tempTemplate = null;

                }
                setState((prevState) => {
                    return {
                        ...prevState,
                        template: tempTemplate,
                        isLoading: false
                    };
                });

            // tslint:disable-next-line: no-any
            }).catch((reason: any) => {
                showNotification(null, reason, 'error');
        });
    };

    // tslint:disable-next-line: no-any

    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };
    
    return (
        <Dialog 
            onClose={onClose} 
            open={state.open}
            fullWidth={true}
            maxWidth="lg"
        >
            <MuiDialogTitle disableTypography={true} >
                <Typography variant="h6" className={classes.heading}>Help</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <DialogContent dividers={true}>
                {
                    !state.isLoading
                    ? (<div dangerouslySetInnerHTML={{__html: state.template || ''}} /> )
                    : 
                    (
                        <div className={classes.loadingWrapper}>
                            <RvLoader size="large" />
                        </div>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button autoFocus={true} onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const AppUserDefinedHelp = gql`
    query AppUserDefinedHelp{
        appUser {
            userDefinedHelp {
                template
            }
        }
    }
`;
import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { client } from '..';

export const useTimeRecording = () => {
    const timeRecordingQuery = useQuery<TimeRecordingData>(TIME_RECORDING_QUERY, {
        fetchPolicy: 'cache-first',
    });

    return {
        ...timeRecordingQuery
    };
};

export type TimeRecordingData = {
    settings: Settings;
};

export type Settings = {
    id: number;
    systems: Systems;
};

export type Systems = {
    timeRecordingDefaults: TimeRecordingDefaults;
    timeRecordingSettings: TimeRecordingSettings;
};

export type TimeRecordingDefaults = {
    id: number;
    config: TimeRecordingDefaultsConfig;
};

export type TimeRecordingDefaultsConfig = {
    id: number;
    timeCaptureMode: string;
};

export type TimeRecordingSettings = {
    id: number;
    config: TimeRecordingSettingsConfig;
};

export type TimeRecordingSettingsConfig = {
    id: number;
    thresholdLevel: number;
};

const TIME_RECORDING_QUERY = gql`
    query TimeRecording {
        settings {
            id
            systems {
                timeRecordingDefaults {
                    id
                    config {
                        id
                        timeCaptureMode
                    }
                }
                timeRecordingSettings {
                    id
                    config {
                        id
                        thresholdLevel
                    }
                }
            }
        }
    }
`;

export function retrieveTimeRecordingData(
    refreshData: boolean,
    onSuccess: (data: TimeRecordingData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: TIME_RECORDING_QUERY,
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: TimeRecordingData }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const useTenant = () => {
    const { data, error, loading } = useQuery<TenantData>(TENANT_QUERY, {
        fetchPolicy: 'cache-first'
    });

    return {
        data,
        error,
        loading,
    };
};

export interface TenantData {
    tenant: Tenant;
}

export interface Tenant {
    companyName:             string;
    tenantId:                string;
    sourceSystem:            string;
    companyUrl:              string;
    leapProvisioningStatus:  string;
    showResetLeapAccessCard: boolean;
}

const TENANT_QUERY = gql`
    query Tenant {
        tenant {
            companyName
            tenantId
            sourceSystem
            companyUrl
            leapProvisioningStatus
            showResetLeapAccessCard
        }
    }
`;

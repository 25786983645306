import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const useHomeCountry = () => {
    const { data, error, loading } = useQuery<HomeCountryData>(HOME_COUNTRY_QUERY, {
        fetchPolicy: 'cache-first'
    });
    return {
        data,
        error,
        loading,
    };
};

export interface HomeCountryData {
    options: Options;
}

export interface Options {
    homeCountry: HomeCountry;
}

export interface HomeCountry {
    stringValue: string;
}

const HOME_COUNTRY_QUERY = gql`
    query homeCountry {
        options {
            homeCountry {
                stringValue
            }
        }
    }
`;

import { WatchQueryFetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export interface RiskLevelData {
    lookup: Lookup;
}

export interface Lookup {
    riskLevel: RiskLevel[];
}

export interface RiskLevel {
    guidId: string;
    description: string;
    level: number;
    sequence: number;
}

const riskLevelQuery = gql`
    query RiskLevel {
        lookup {
            riskLevel {
                guidId
                description
                level
                sequence
            }
        }
    }
`;

export const useRiskLevel = (fetchPolicy: WatchQueryFetchPolicy) => {

    const { data, error, loading} = useQuery<RiskLevelData>(riskLevelQuery, {
        fetchPolicy: fetchPolicy
    });

    return {
        data,
        error,
        loading
    };

};
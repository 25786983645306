import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const useRegionSettings = () => {
    const { data, error, loading } = useQuery<RegionsSettingsData>(REGION_SETTINGS_QUERY, {
        fetchPolicy: 'cache-first'
    });

    return {
        data,
        error,
        loading,
    };
};

export interface RegionsSettingsData {
    settings: Settings;
}

export interface Settings {
    id:      number;
    systems: Systems;
}

export interface Systems {
    regionSettings: RegionSettings;
}

export interface RegionSettings {
    id:     number;
    config: Config;
}

export interface Config {
    id:                 number;
    currencyCode:       string;
    currencySymbol:     string;
    financialYearStart: Date;
    homeCountry:        string;
    timeZone:           string;
    timeZoneDescription: string;
    taxLabel:           string;
    taxPercentage:      number;
    baseUtcOffset:      number;
}

const REGION_SETTINGS_QUERY = gql`
    query regionSettings {
        settings {
            id
            systems {
                regionSettings {
                    id
                    config {
                        id
                        currencyCode
                        currencySymbol
                        financialYearStart
                        homeCountry
                        timeZone
                        timeZoneDescription
                        taxLabel
                        taxPercentage
                        baseUtcOffset
                    }
                }
            }
        }
    }
`;

import glamorous from 'glamorous';
import ForwardIcon from '@material-ui/icons/Forward';
import InfoIcon from '@material-ui/icons/InfoOutlined';

export const IconButtonWrapper = glamorous.div<{ theme?: object }>((props) => ({
    position: 'absolute',
    right: '5px',
    top: '5px',
    display: 'flex',
    alignItems: 'center',
    margin: '0 -3px',
    '& > .MuiIconButton-root': {
        margin: '0 3px',
        '& .MuiSvgIcon-root': {
            color: 'rgb(0, 120, 212) !important'
        },
    },
    '@media print': {
        display: 'none !important',
    }
}));

// tslint:disable-next-line:no-any
export const InfoHoverIcon = glamorous(InfoIcon)<{ theme?: any }>((props) => ({
    color: props.theme.TemplateColor.Primary,
    position: 'absolute',
    left: '5px',
    top: '5px',
    '@media print': {
        display: 'none !important',
    }
}));

export const HelpWrapper = glamorous.div<{ theme?: object }>((props) => ({
    position: 'absolute',
    left: '5px',
    top: '5px',
    display: 'flex',
    alignItems: 'center',
    margin: '0 -2.5px',
    '& > *': {
        margin: '0 2.5px',
    },
    '@media print': {
        display: 'none !important',
    },
}));

// tslint:disable-next-line:no-any
export const UpIcon = glamorous(ForwardIcon)<{ theme?: any }>(props => ({
    transform: 'rotate(270deg)',
    color: 'green',
}));

// tslint:disable-next-line:no-any
export const DownIcon = glamorous(ForwardIcon)<{ theme?: any }>(props => ({
    transform: 'rotate(90deg)',
    color: 'red'
}));

export const FooterDescription = glamorous.div<{ theme?: object }>((props) => ({
    fontSize: '1rem',
    textAlign: 'center',
}));

export interface ChartCommonProps {
    index?: number;
    onCloseChart?: (category: string) => void;
    description?: string;
    onMaximize?: (option?: number, additionalOption?: number) => void;
    showAllRecords?: boolean;
    showScreenshot?: boolean;
}

export interface ChartCommonState {
    open: boolean;
    anchorEl: Element | ((element: Element) => Element) | null | undefined;
}
import { WatchQueryFetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export interface CorrespondenceActivityData {
    lookup: Lookup;
}

export interface Lookup {
    correspondenceActivity: CorrespondenceActivity[];
}

export interface CorrespondenceActivity {
    guid: string;
    description: string;
    sequence: number;
}

export interface CorrespondenceActivityParams {
    isSystem?: boolean | null;
}

export const CorrespondenceActivityQuery = gql`
query correspondenceActivityLookup($isSystem: Boolean) {
    lookup {
      correspondenceActivity(isSystem: $isSystem) {
        guid
        description
        sequence
        isSystem
      }
    }
  }
`;

export const useCorrespondenceActivity = (variables: CorrespondenceActivityParams, fetchPolicy?: WatchQueryFetchPolicy) => {

    const { data, error, loading} = useQuery<CorrespondenceActivityData, CorrespondenceActivityParams>(CorrespondenceActivityQuery, {
        variables: variables,
        fetchPolicy: fetchPolicy
    });

    return {
        data,
        error,
        loading
    };

};
import { DateType } from '@date-io/type';
import moment from 'moment';

export const isDateStringInFormat = (dateString: string): boolean =>  {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
};
  
export const formatDateString = (dateValue: string | Date | DateType | null): string | null => {
    if (dateValue === null) {
        // Decide on the behavior when null is passed. Returning null here, but you can adjust as needed.
        return null;
    } else if (typeof dateValue === 'string') {
        if (isDateStringInFormat(dateValue)) {
            return dateValue; // Return as is if the string is already in YYYY-MM-DD format
        } else {
            // Assume the string is in a different format and needs conversion
            return moment(dateValue).format('YYYY-MM-DD');
        }
    } else if (moment.isMoment(dateValue)) {
        // If it's a Moment object
        return dateValue.format('YYYY-MM-DD');
    } else if (dateValue instanceof Date) {
        // If it's a Date object
        return moment(dateValue).format('YYYY-MM-DD');
    } else {
        throw new Error('Invalid dateValue type');
    }
};